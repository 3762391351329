<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Employment">
      <template #page-header>
        <h2 class="page-title">Employee</h2>
        <r-menu-tab :level="2" />
      </template>
      <template #action-bar>
        <EmploymentHeader
          :filter="true"
          :search="true"
          :open="isFilterOpen"
          @onFiltered="isFilterOpen = !isFilterOpen"
          @onToggleFilterStatus="onToggleFilterStatus"
          @onReset="resetFilter"
          :loadData="isEmployeeGeneralListLoading"
          @onSearch="searchIconClick"
          :filterStatus="filterStatus"
          :filterData="filterData"
        >
          <template #addBtn>
            <b-button
              tag="router-link"
              class="is-command"
              icon-left="plus"
              to="/employment/create"
            >
              New
            </b-button>
          </template>
        </EmploymentHeader>
      </template>

      <template #page-content>
        <div id="table-request-employment">
          <b-table
            :data="employeeGeneralList"
            :per-page="perPage"
            :loading="isEmployeeGeneralListLoading"
            @page-change="onPageChange"
            :show-detail-icon="showDetailIcon"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkable
            checkbox-position="left"
            :checked-rows.sync="checkedRows"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="users.first_name"
                label="Employee's Name"
                v-slot="props"
                sortable
                width="18%"
              >
                <div class="columns employment-table-name">
                  <img
                    class="employment-profile-picture"
                    :src="
                      determineProfilePicture(
                        props.row.profilePicture,
                        props.row.gender
                      )
                    "
                  />
                  <span class="is-capitalize">
                    {{ props.row.name }}
                  </span>
                </div>
              </b-table-column>

              <b-table-column
                field="positions.position_name"
                label="Title"
                v-slot="props"
                sortable
                width="12%"
              >
                <span class="is-capitalize">
                  {{ props.row.title ? props.row.title : '-' }}
                </span>
              </b-table-column>

              <b-table-column
                field="users.work_email"
                label="Email"
                v-slot="props"
                sortable
                width="15%"
              >
                {{ textSubString(props.row.workEmail, '40', '...') }}
              </b-table-column>

              <b-table-column
                field="users.city"
                label="City"
                v-slot="props"
                sortable
                width="10%"
              >
                <span class="is-capitalize">
                  {{ props.row.city ? props.row.city : '-' }}
                </span>
              </b-table-column>

              <b-table-column
                field="users.province"
                label="Province"
                v-slot="props"
                sortable
                width="10%"
              >
                <span class="is-capitalize">
                  {{ props.row.province ? props.row.province : '-' }}
                </span>
              </b-table-column>

              <b-table-column
                field="employments.is_active"
                label="Status"
                v-slot="props"
                sortable
                width="10%"
                class="table-is-active"
              >
                <span
                  class="is-capitalize table-is-active__active"
                  v-if="props.row.isActive === '1'"
                >
                  Active
                </span>
                <span class="is-capitalize table-is-active__inactive" v-else>
                  Inactive
                </span>
              </b-table-column>

              <b-table-column
                field="employments.annual_leave"
                label="Used/Annual Leave"
                v-slot="props"
                sortable
                width="15%"
              >
                <span class="is-capitalize">
                  {{
                    formatAnnualLeave(
                      props.row.annualLeave,
                      props.row.remainingAnnualLeave,
                      props.row.positionType
                    )
                  }}
                </span>
              </b-table-column>

              <b-table-column
                label="Action"
                v-slot="props"
                width="10%"
                centered
              >
                <router-link :to="`/employment/detail/${props.row.userId}`">
                  <b-icon
                    type="is-primary"
                    icon="eye"
                    custom-size="mdi-18px"
                    class="employment-all-icon"
                  ></b-icon>
                </router-link>
                <router-link :to="`/employment/edit/${props.row.userId}`">
                  <b-icon
                    type="is-primary"
                    icon="pencil"
                    custom-size="mdi-18px"
                    class="employment-all-icon"
                  ></b-icon>
                </router-link>
                <b-icon
                  type="is-primary"
                  icon="archive"
                  custom-size="mdi-18px"
                  class="employment-all-icon"
                  @click.native="archiveWarning(props.row.userId)"
                  v-if="props.row.isActive !== '1'"
                ></b-icon>
                <b-icon
                  type="is-disabled"
                  icon="archive"
                  custom-size="mdi-18px"
                  class="employment-disabled-icon"
                  v-else
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <div class="has-text-centered" v-if="isLoadMore">Please Wait ...</div>
      </template>

      <!-- IS MODAL -->
    </r-page>
    <r-page-modal
      :prompt-on-leave="closeModalRequest"
      prompt-message="Are you sure?"
      v-if="employeeHasMember"
      @hide="closeModalRequest"
      :is-modal="true"
      key="modalKey"
      class="modal-allowed-capacity"
    >
      <template #page-content>
        <h3 class="modal-title has-text-weight-black">
          Change My Team Reporting To
          <span class="office-pointer-click is-pulled-right">
            <b-icon icon="close" size="is-small" />
          </span>
        </h3>
        <ValidationObserver ref="observer">
          <form
            @submit.prevent="handleUpdate"
            class="is-label-14px-normal form-timeoff-type"
          >
            <div
              v-for="(item, id) in employeeMemberList"
              :key="id"
              class="mb-3"
            >
              <div class="member-team">
                <div class="mr-2">
                  <figure class="image is-32x32">
                    <img
                      class="is-rounded"
                      src="https://bulma.io/images/placeholders/128x128.png"
                    />
                  </figure>
                </div>
                <div class="pt-1">
                  <h3 class="member-name">{{ item.fullName }}</h3>
                </div>
              </div>
              {{ employees.id }}
              <div class="column is-12">
                <h4 class="">Reporting to</h4>
              </div>
              <div class="column is-12">
                <div class="columns is-multiline is-mobile">
                  <div class="column is-12">
                    <b-autocomplete
                      v-model="item.reporting_to"
                      open-on-focus
                      icon-right="chevron-down"
                      :data="employees"
                      placeholder="e.g. Choose Employee.."
                      field="firstName"
                      @select="reportingToSelected($event, item.employmentId)"
                      @focus="getApiEmployees([])"
                      @typing="getApiEmployees"
                      @infinite-scroll="addPageReportingTo"
                      :check-infinite-scroll="true"
                    >
                      <template slot-scope="props">
                        {{ props.option.firstName }}
                        {{ props.option.middleName }}
                        {{ props.option.lastName }}
                      </template>
                    </b-autocomplete>
                    <div
                      class="columns is-multiline mt-2"
                      v-if="selectedReportedToInfo"
                    >
                      <div class="column is-1">
                        <div class="reporting-to-profile-picture-container">
                          <img
                            :src="
                              determineProfilePicture(selectedReportedToInfo)
                            "
                          />
                        </div>
                      </div>
                      <div class="column reporting-to-component">
                        <div
                          class="column is-12 reporting-to-component__name has-text-weight-semi-bold"
                        >
                          {{
                            selectedReportedToInfo.firstName +
                            ' ' +
                            selectedReportedToInfo.lastName
                          }}
                        </div>
                        <div
                          class="column is-12 reporting-to-component__position"
                        >
                          {{
                            selectedReportedToInfo.employment
                              ? selectedReportedToInfo.employment.position
                                  .positionName
                              : '-'
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns container px-2 mt-3">
              <div class="column">
                <b-button @click="closeModalRequest" expanded type="is-light">
                  Cancel
                </b-button>
              </div>

              <div class="column is-6">
                <b-button @click="handleUpdate()" expanded type="is-primary">
                  Save
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </r-page-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import EmploymentFilterComponent from './EmploymentFilterComponent'
import debounce from 'lodash/debounce'
import ApiService from '@/services/common/api.service'
// import SearchFilter from '../../components/FilterContent/SearchFilter.vue'
import EmploymentHeader from '../../components/Header/EmploymentHeader.vue'
// import FilterVue from '../../components/Filter.vue'
// import EmploymentFilter from '../../components/FilterContent/EmploymentFilter.vue'

export default {
  components: { EmploymentHeader },
  computed: {
    ...mapGetters({
      employeeGeneralList: 'employment/getEmployeeGeneralList',
      employeeMemberList: 'employment/getEmployeeMember',
      employeeHasMember: 'employment/getEmployeeHasMember',
      employeeNotHaveMember: 'employment/getNotHaveMember',
    }),
  },
  data() {
    return {
      checkedRows: [],
      isRowCheckable: true,
      stickyHeaders: true,

      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,

      defaultSortOrder: 'asc',
      sortField: 'users.first_name',
      sortOrder: 'asc',
      search: '',

      showDetailIcon: true,

      isEmployeeGeneralListLoading: false,
      isLoadMore: false,

      selectedEmployee: null,

      // filter
      isFilterOpen: false,
      filterStatus: null,

      reportingToFirstName: '',
      employees: [],
      selectedReportedToInfo: null,
      employment_member: [],
      employments: null,
      isShowModal: false,

      filterData: [
        {
          title: 'Status',
          list: [
            { id: 1, statusName: 'Active' },
            { id: 0, statusName: 'Inactive' },
          ],
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      actionLoadEmployeeGeneralList: 'employment/fetchEmployeeListGeneral',
      archiveEmployee: 'employment/archiveEmployee',
      getMemberOfEmployee: 'employment/getMemberOfEmployee',
      updateReportingTo: 'employment/updateEmployeeMember',
      onCloseModal: 'employment/onCloseModal',
    }),

    ...mapMutations({
      setEmployeeListsGeneral: 'employment/setEmployeeListGeneral',
    }),

    /**
     *  HandleClick for change filter
     *  @param {number} filterStatus - value filterStatus
     */
    async onToggleFilterStatus(filterStatus) {
      this.isFilterOpen = false
      this.filterStatus = filterStatus
      await this.resetState()
    },
    async resetFilter() {
      this.page = 0
      this.lastPage = 0
      this.filterStatus = null
      this.setEmployeeListsGeneral([])
      await this.loadEmployeeGeneralList()
    },

    /**
     *  Function for format leave date
     * @param {string} annualLeave
     * @param {string} remaining
     * @param {string} positionType
     * @return {string} formatted string
     */
    formatAnnualLeave(annualLeave, remaining, positionType) {
      let formatAnnualLeave = '-'
      let formatRemaining = '-'
      let formatPositionType = ''
      if (annualLeave) {
        formatAnnualLeave = annualLeave
      }
      if (remaining) {
        formatRemaining = remaining
      }
      if (positionType) {
        formatPositionType =
          parseInt(positionType) === 2 ? '(+1 Every Month)' : '(Fixed Time Off)'
      }
      return `${formatRemaining}/${formatAnnualLeave} ${formatPositionType}`
    },

    /**
     * Function to Load more data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadEmployeeGeneralList()
        this.isLoadMore = false
      }
    },

    /**
     * Return user profile picture by gender
     * @param {string} searchInput
     */
    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    /**
     * Reset filter parameter
     * @param {bool} searchInput - input for search filter
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setEmployeeListsGeneral([])
      await this.loadEmployeeGeneralList(searchInput)
    },

    /**
     *  Function to sort table
     * @param {string} field - field type
     * @param {string} order - order type
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     *  Handle for change pagination
     * @param {number} page - page number
     */
    onPageChange(page) {
      this.page = page
      this.loadEmployeeGeneralList()
    },
    getFilter() {
      if (this.filterStatus.length === 2) {
        return null
      } else if (this.filterStatus.includes('active')) {
        return 1
      } else if (this.filterStatus.includes('inactive')) {
        return 0
      }
      return null
    },

    /**
     *  Function to fetch peopla data list
     * @param {bool} isFiltering
     */
    async loadEmployeeGeneralList(searchInput) {
      this.isEmployeeGeneralListLoading = true
      try {
        const params = {
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
          isActive: this.filterStatus,
        }
        const response = await this.actionLoadEmployeeGeneralList(params)

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isEmployeeGeneralListLoading = false
    },

    /**
     * Return user profile picture by gender
     * @param {string} string - url profile picture
     * @return {string}
     */
    determineProfilePicture(user, gender) {
      if (!user) {
        if (gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user
    },

    /**
     * Handle to close request modal
     */
    async closeModalRequest() {
      await this.onCloseModal()
      this.resetForm()
    },

    /**
     * handle to reset form
     */
    resetForm() {
      this.form = {
        id: null,
        date: null,
        hours: null,
        reason: null,
        additionalApproverId: null,
      }
      this.searchUser = null
      this.selectedUser = null
    },

    /**
     * handle to update reporting to
     */
    async handleUpdate() {
      await this.updateReportingTo(this.employments)
    },

    /**
     * Handle to display archive warning
     * @param {number} id - user id
     */
    async archiveWarning(id) {
      await this.archiveEmployee(id)

      if (!this.employeeHasMember) {
        let res = await this.$swal({
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'button is-primary',
            cancelButton: 'button',
          },
          text: 'Are you sure you want to archive this user?',
        })

        try {
          if (res && res.isConfirmed) {
            let form = {}
            await this.archiveEmployee(id, form)

            this.$swal({
              icon: 'success',
              titleText: 'User is successfully archived',
              confirmButtonText: 'Done',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
            this.resetState()
          }
        } catch (e) {
          this.$swal({
            icon: 'error',
            titleText: 'Failed. Please try again!',
            text: e.response.data.message,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'button is-primary',
            },
          })
        }
      }
    },

    /**
     * Handle select reporting to
     * @param {string} emplId - employee id
     * @param {string} memberId - member id
     * @return {string}
     */
    async reportingToSelected(emplId, memberId) {
      const initMember = {
        id: memberId,
        reporting_to: emplId.id,
      }
      this.employment_member.push(initMember)
      this.employments = this.employment_member
    },

    /**
     * Function to get employees
     * @param {string} search
     */
    getApiEmployees(search) {
      this.employees = []
      this.getNextApiEmployees('/api/space-roketin/user?page=1', search)
    },

    /**
     * Function to get next employees
     * @param {string} nextUrl
     * @param {string} search
     */
    getNextApiEmployees: debounce(function (nextUrl, search) {
      if (nextUrl == null) {
        return
      }

      if (search) {
        search = {
          search: search,
        }
      }

      ApiService.get(nextUrl, search).then((response) => {
        if (this.employees.length != 0) {
          this.employees.splice(this.employees.length - 1, 1)
        }

        let employees = response.data.data

        employees.forEach((employee) => {
          this.employees.push(employee)
        })

        let key = employees.length - 1

        this.last_employee_id = employees[key].id

        this.employee_next_page_url = response.data.links.next
        this.form.user_id = ''

        if (response.data.links.next == null) {
          return
        }
      })
    }, 500),

    /**
     * Function to add reporting to
     */
    addPageReportingTo() {
      this.employee_next_page_url += 1
      this.getNextEmployees(
        '/api/space-roketin/user?page=' + this.employee_next_page_url
      )
    },
  },

  async mounted() {
    this.setEmployeeListsGeneral([])
    await this.loadEmployeeGeneralList()

    this.employeeMemberList.map((val) => {
      return {
        employment_id: val.employment_id,
        user_id: val.user_id,
        full_name: val.full_name,
        position_name: val.position_name,
        reporting_to: null,
      }
    })
  },
  watch: {
    // This will probably have something to do with checkbox,
    // so I'm not going to delete this code yet
    selectedEmployee(data) {
      this.$router.push(`/employment/detail/${data.userId}`)
    },
  },
}
</script>
